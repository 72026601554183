import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@services';

@Component({
  selector: 'app-subscription',
  standalone: true,
  imports: [],
  templateUrl: './subscription.component.html',
  styleUrl: './subscription.component.scss'
})
export class SubscriptionComponent {
  protected data = inject(MAT_DIALOG_DATA);
  private auth = inject(AuthService);
  protected dialogRef = inject(MatDialogRef);

  gotTo(): void {
    if (typeof window !== 'undefined') {
      window.open(this.data.url, '_blank');
      this.data.isLoggedIn && this.auth.logout();
      this.dialogRef.close();
    }
  }
}
